import React, { useEffect, useRef, useState} from 'react';
import { CanvasContext } from '../UserContext'

import { fabric } from 'fabric';
import axios from 'axios';

import styles from './css/canvas.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIgloo, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import gsap from 'gsap/gsap-core';
import $ from 'jquery';

import {
    isMobile,
} from "react-device-detect";

import PopUpRotate from "./Canvas_components/PopUpRotate.jsx";

/* Import Components */
import CanvasTitle from './Canvas_components/Title'
import CanvasUpload from './Canvas_components/Upload'
import CanvasDraw from './Canvas_components/Draw'
import CanvasText from './Canvas_components/Text'
import PopUp from './Canvas_components/PopUp'
import CatchError from './Canvas_components/CatchError'
import PopUpClear from './Canvas_components/PopUpClear'
// import Selector from './Canvas_components/Selector'

import helpers from './Helpers/Anims';
import framewall from '../assets/frame.png'

const MY_URL = process.env.REACT_APP_SECRET_URL

function Canvas(props){

    const [landscape, setOrientation] = useState(false)
    const [onRotate, setOnRotate] = useState(false)

    useEffect(() => {

        if (window.matchMedia("(orientation: portrait)").matches) {
            setOrientation(false)
        }
            
        if (window.matchMedia("(orientation: landscape)").matches) {
            setOrientation(true)
        }

        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", function() {
                setOnRotate(!onRotate)
            }, false);
        }
    
    }, [onRotate])


    const [show, setShow] = useState(true)
    const [canvas2, setCanvas2] = useState(false)
    const [textbox2, setTexbox2] = useState(false)
    const [canvas_bg, setCanvas_bg] = useState("#af1c86")
    var  textbox, canvas, textbox1, textboxTitle, divWhite, frame

    const inputref = useRef();
    const textType = useRef();

    const [inputValue, setInputValue] = useState(5)

    const name = localStorage.getItem('name');

    const [activeMenuBg, setActiveMenuBg] = useState(false)
    const [activeMenuText, setActiveMenuText] = useState(false)
    const [activeMenuDraw, setActiveMenuDraw] = useState(false)


    const [mousePos, setMousePos] = useState({});

    useEffect(() => {
      const handleMouseMove = (event) => {
        setMousePos({ x: event.clientX, y: event.clientY });
      };
  
      window.addEventListener('click', handleMouseMove);
  
      return () => {
        window.removeEventListener(
          'click',
          handleMouseMove
        );
      };
    }, []);
  
    useEffect(() => {
  
        const posClick = { x: '', y: ''}
  
        // Switch by Width
        switch (true) {
            case  mousePos.x < (window.innerWidth / 3 ):
                posClick.x = 'left'
            break;
    
            case  ( ( mousePos.x > (window.innerWidth / 3 ) ) && (mousePos.x < ( window.innerWidth / 3 * 2)) ):
                posClick.x = 'middle'
            break;
    
            case  ( ( mousePos.x > (window.innerWidth / 3  * 2 ) ) && (mousePos.x < ( window.innerWidth / 3 * 3)) ):
                posClick.x = 'rigth'
            break;
        
            default: 
            break;
        }
  
  
        // Switch by Height
        switch (true) {
            case  mousePos.y < (window.innerHeight / 3 ):
                posClick.y = 'top'
            break;
    
            case  ( ( mousePos.y > (window.innerHeight / 3 ) ) && (mousePos.y < ( window.innerHeight / 3 * 2)) ):
                posClick.y = 'middle'
            break;
    
            case  ( ( mousePos.y >= (window.innerHeight / 3 * 2) ) && (mousePos.y <= ( window.innerHeight / 3 * 3)) ):
                posClick.y = 'bottom'
            break;
    
        
            default: 
            break;
        }
  
    }, [mousePos])


    useEffect(() => {
      console.log('textbox', textbox2)
    }, [activeMenuText])
    


    useEffect(() => {
      if(activeMenuText){
        $("#textSelector").css('background', '#f2f2f2')
      } else {
          $("#textSelector").css('background', 'transparent')
      }

    }, [activeMenuText])


    useEffect(() => {

        if(activeMenuDraw){
          $("#drawSelector").css('background', '#f2f2f2')
        } else {
            $("#drawSelector").css('background', 'transparent')
        }
  
    }, [activeMenuDraw])


    useEffect(() => {
    if(!activeMenuText && !activeMenuDraw){
        $("#selectorPointer").css('background', '#f2f2f2')
    } else {
        $("#selectorPointer").css('background', 'transparent')
    }

    }, [activeMenuText, activeMenuDraw])
    


    var isRedoing = false;
    var h = [];
    var obj = []
    const timeR = useRef(null)
    const cancel = useRef(false)
    
    var shadow = new fabric.Shadow({
        color: 'black',
        blur: '10',
        offsetX: '2',
        offsetY: '2'
    });

    useEffect( ()  => {

        canvas = new fabric.Canvas("c", { preserveObjectStacking:true ,  backgroundColor: '#032835'} );
        canvas.selection = false;

        setCanvas_bg(canvas)

        setCanvas2(canvas)
        
        if(name != null){
            textbox = new fabric.Textbox( `${name}`, {
                fontSize: 25,
                right: 10,
                fontFamily: 'Caveat',
                top: 440,
                width: 620,
                textAlign: 'right',
                editable: false,
                evented: false,
                selectable: false,
                fill: '#fff',
            });
            
            canvas.add(textbox)

            console.log('textbox', textbox)

            fabric.Image.fromURL('', function(myImg) {
                frame = myImg.set({
                    id:'frame',
                    editable: false,
                    evented: false,
                    selectable: false,
                    zIndex: 1000,
                    absolutePositioned: true
                })
                frame.scaleToWidth(640);
                canvas.add(frame)

                frame.moveTo(10)
            })

            setTexbox2(textbox)
        } else {
            props.history.push("/submit")
        }


        textbox1 = new fabric.Textbox("Type your message here", {
            fontSize: 35,
            left: 80,
            top: 160,
            padding: 40,
            id: 'textbox',
            width: 480,
            textAlign: 'center',
            fill: '#fff',
            fontFamily: 'Open Sans',
        });


        canvas.discardActiveObject()

        textbox1.selectAll()

        canvas.add(textbox1);
        canvas.bringForward(textbox1);

        divWhite = new fabric.Textbox("",{
            fontSize: 1000,
            left: 0,
            fontFamily: 'Oooh Baby',
            fontWeight: 'bold',
            top: -10,
            height: 1000,
            width: 640,
            textAlign: 'center',
            editable: false,
            evented: false,
            selectable: false,
            // fill: '#ffffff',
            backgroundColor:  'transparent'
            // shadow: shadow
        });
        
        canvas.add(divWhite)
        divWhite.sendBackwards()
        canvas.bringToFront(textbox);

        // canvas.setActiveObject(textbox1)
        // textbox1.enterEditing();
        // setActiveMenuText(true)
        canvas.renderAll();

        function addDeleteBtn(x, y){
            $(".deleteBtn").remove();
            var btnLeft = x-40;
            var btnTop = y+15;
            var deleteBtn = '<img src="/assets/trash.svg" class="deleteBtn" style="position:absolute;top:'+btnTop+'px;left:'+btnLeft+'px;cursor:pointer;width:20px;height:20px; background: white; padding: 5px; border-radius: 22px;"/>';
            $(".canvas-container").append(deleteBtn);
            $(".canvas-container").css("overflow", "hidden");
        }

        // document.addEventListener('keydown', onCero, false);

        function onCero(e){ 
            if (e.key == "Backspace") {
                canvas.remove(canvas.getActiveObject());
                $(".deleteBtn").remove();
                setActiveMenuText(false)
            }
            return;
        };

        canvas.on('selection:cleared',function(e){
            console.log('fired')
            $(".deleteBtn").remove();
        })
        
        canvas.on('object:selected',function(e){

            addDeleteBtn(e.target.oCoords.tr.x, e.target.oCoords.tr.y);

            setTimeout(async () => {

                if(e.target.id == "textbox"){
                    if(e.target.selected && !e.target.isEditing){
                        document.addEventListener('keydown', onCero(e), false);
                        console.log('textbox selected')
                        setActiveMenuText(true)
                    } 

                    if(e.target.text === "Type your message here") {
                        e.target.text = "";
                        console.log('e.target.text', e.target.text)
                        // canvas.renderAll();
                    };

                } else {
                    // onCero()
                    
                }
                
            }, 100);

        });

        canvas.on("before:transform", function(e){
            var object_type = obj.type;

            if(object_type == "textbox"){
                setActiveMenuText(true)

                if(e.target.text === "Type Here") {
                    e.target.text = "";
                    console.log('e.target.text', e.target.text)
                    canvas.renderAll();
                };
            }
        });


        canvas.on('mouse:down',function(e){

            if(!canvas.getActiveObject())
            {
                $(".deleteBtn").remove();

                setActiveMenuText(false)
            } else {
                var obj = canvas.getActiveObject();
                var object_type = obj.type;
    
                console.log('object_type', object_type)

                if(object_type == "textbox"){
                    console.log('editing textbox')
                    setActiveMenuText(true)

                    if(e.target.text === "Type Here") {
                        e.target.text = "";
                        console.log('e.target.text', e.target.text)
                        canvas.renderAll();
                    };
                }
    
            }
        });
        
        canvas.on('object:modified',function(e){
            addDeleteBtn(e.target.oCoords.tr.x, e.target.oCoords.tr.y);
        });
        
        canvas.on('object:scaling',function(e){
            $(".deleteBtn").remove(); 
        });
        canvas.on('object:moving',function(e){
            $(".deleteBtn").remove(); 
        });
        canvas.on('object:rotating',function(e){
            $(".deleteBtn").remove(); 
        });
        $(document).on('click',".deleteBtn",function(){
            if(canvas.getActiveObject())
            {
                canvas.remove(canvas.getActiveObject());
                $(".deleteBtn").remove();

                setActiveMenuText(false)
            }
        });


        textbox1.on('changed', function() {
            var currentCharacters = textbox1.text.length;
            let newFontSize;
            console.log('currentCharacters', currentCharacters)

            if(currentCharacters < 50){
                newFontSize = 35;
                textbox.set('fontSize', newFontSize);
            } else if(currentCharacters >= 50 && currentCharacters < 70){
                newFontSize = 30;
                textbox1.set('fontSize', newFontSize);
            } else if (currentCharacters >= 100 && currentCharacters < 150){
                newFontSize = 25;
                textbox1.set('fontSize', newFontSize);
            } else if (currentCharacters >= 150 ){
                newFontSize = 22;
                textbox1.set('fontSize', newFontSize);
            }

        })

    }, []);

    const tlCanvas = useRef();
    const elCanvasForm = useRef();
    const elCanvasBg = useRef();

    useEffect(() => {
        tlCanvas.current = gsap.timeline({
            paused: false
        })

        helpers.fadeIn("#canvas", 1)

        // $('body').css('background-size', '0')

    }, [])


    function uploadCompleted(){
        $("#objectUploading").hide()
        $("#objectDone").css('opacity', '1')

        $("#pUploading").hide()
        $("#pDone").show()

        $("#buttonUploading").hide()
        $("#buttonDone").show()
    }
   

    async function afterSend(url) {

        const AWS = require('aws-sdk')
        AWS.config.update({
            // accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            // secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
            region: 'us-east-1',
            acl: 'public-read'
        })

        const s3 = new AWS.S3();
        var params = {
            Bucket: process.env.REACT_APP_BUCKET,
        };

        s3.listObjectsV2(params, async function (err, data) {
            await data.Contents.map( async (element) => {
                let responseUrl = await element.Key.substring(element.Key.indexOf("_")+1);

                if(responseUrl == url){
                    uploadCompleted()
                    // props.history.push("/view")
                }
            });
        })

    }

    async function createImg(){
        canvas2.discardActiveObject().renderAll();

        let canvas3 = document.getElementById('c')
        let config = {
            'content-type' : 'aplicattion/json',
            'Access-Control-Allow-Origin': '*'
        }

        // await addText2() // CHECK IF WE NEED THIS TO RERENDER TEXT SIGN
        await togglePopup()

        canvas3.toBlob( async function(blob) {

            const regex = /[^\w]+/gi
            let nameRegex = name.replace(regex, '')
    
            function getCurrentDate() {
                const date = new Date();
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const seconds = date.getSeconds().toString().padStart(2, '0');
                return `${year}_${month}_${day}_${hours}${minutes}${seconds}`;
            }
              
            const fileDate = getCurrentDate();

            const formData = new FormData();
            const myFileImg = await new File(
                [blob],
                `${fileDate}_${nameRegex}.png`,
            { type: 'image/png' });

            formData.append('myMessage', myFileImg);

            localStorage.setItem('messageUrl', myFileImg.name);

            await axios.post(`${MY_URL}view`, formData, config)
            .then( res => {
                console.log('res', res)
                setTimeout(() => {
                    uploadCompleted()
                }, 3000);
            })
            .catch(err => {
                console.log('err', err)
                cancelSend()
                document.getElementById('uploadError').style.display = 'block'
            });

            setTimeout(() => {
                uploadCompleted()
            }, 5000);
        }, "image/jpeg", 0.8 );
    }


    function cancelSend(){
        document.getElementById('popUp').style.display = 'none'
        cancel.current = true
        window.clearTimeout(timeR.current)
        canvas2.isDrawingMode = false;
    }

    async function togglePopup() {

        setActiveMenuBg(false)
        setActiveMenuText(false)
        setActiveMenuDraw(false)

        obj = canvas2.getObjects()

        console.log('obj', obj)

        let imagesObj ;

        await obj.map( x => {
            if(x.id == "fabric_js"){
                console.log('x', x)
                imagesObj = 1
            } 
        })

        // if( imagesObj != 1){
            
            // alert('Please, share a picture')
            // document.getElementById('empty_container').style.display = 'block'
            // setTimeout(() => {
            //     document.getElementById('empty_container').style.display = 'none'
            // }, 3000);

        // } else {
            // cancel.current = false
            document.getElementById('popUp').style.display = 'block'
            timeR.current = setTimeout( async () =>{
                await createImg()
                window.clearTimeout(timeR.current)
            }, 5000)
        // }
    }


    //TEXT
    function addText() {    
        //Genero la funcion de agregar textos
        document.getElementById('empty_container').style.display = 'none'
        obj = canvas2.getObjects()
        
        let texts = [];

        obj.map((el, id) => {
            if(el.text !== undefined){
                texts.push(el)
            }
        })

        if( texts.length == 1){
            document.getElementById('empty_container').style.display = 'none'
            canvas2.isDrawingMode = false;
            inputref.current.style.display = 'none';
            textType.current.style.display = 'inline-block';

            textbox = new fabric.Textbox("", {
                fontSize: 50,
                left: 200,
                top: 300,
                id: 'textbox',
                width: 250,
                textAlign: 'center',
                fill: 'white',
                fontFamily: 'Open Sans',
            });
            canvas2.discardActiveObject()
            canvas2.setActiveObject(textbox)
            textbox.enterEditing();
            canvas2.add(textbox);
            

        } else {
            document.getElementById('empty_container').style.display = 'none'
            canvas2.isDrawingMode = false;
            inputref.current.style.display = 'none';
            textType.current.style.display = 'inline-block';
            let textbox1 = texts[1]
            canvas2.setActiveObject(textbox1)
            textbox1.enterEditing();
        }
    }

    async function setText( param, value){

        if(canvas2.getActiveObject()){
            var obj = canvas2.getActiveObject();

            if(obj.id != 'fabric_js'){
                obj.set( param, value);
                canvas2.renderAll();
                canvas2.discardActiveObject()
                canvas2.setActiveObject(obj)
                obj.enterEditing()
                canvas2.renderAll();
            }
        }
    }


    function goBack(){
        document.getElementById('clear').style.display = 'block'
    }


    useEffect(() => {
        if(canvas2.isDrawingMode == false ){
            canvas2.forEachObject(function(obj) {
                obj.editable = true;
            });
        }

    }, [canvas2.isDrawingMode])
    


    return (
        <>
        <CanvasContext.Provider value={{canvas2, obj, inputref, textType, textbox2, h, isRedoing, name }}>
            <p  style={{fontFamily: 'Crafty Girls', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Cedarville Cursive', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Rock Salt', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Homemade Apple', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Shadows Into Light', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Caveat', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Ooh Baby', display: 'inline'}}></p>
        { show ? 
        (<div style={{position:'absolute', top:'0', zIndex: '10', left: 0}}>

            {landscape && isMobile ?
                <PopUpRotate/>
            :
            null }

            <div ref={elCanvasBg} className={styles.bg}></div>
            <div id="canvas" className={styles.card_canvas}>
                
                <div ref={elCanvasForm} className={styles.container}>

                <div className={styles.hand_buttons}>

                      {/* UPLOAD IMG */}
                      {/* <Selector  activeMenuDraw={activeMenuDraw} setActiveMenuDraw={setActiveMenuDraw} activeMenuText={activeMenuText} setActiveMenuText={setActiveMenuText}  activeMenuBg={activeMenuBg} setActiveMenuBg={setActiveMenuBg} textType={textType} inputref={inputref}/> */}
                      
                      {/* UPLOAD IMG */}
                      <CanvasUpload setActiveMenuDraw={setActiveMenuDraw} setActiveMenuText={setActiveMenuText} textType={textType} inputref={inputref}/>

                      {/* DRAW */}
                      <CanvasDraw activeMenuBg={activeMenuBg} setActiveMenuBg={setActiveMenuBg} activeMenuText={activeMenuText} setActiveMenuText={setActiveMenuText} inputValue={inputValue} setInputValue={setInputValue} activeMenuDraw={activeMenuDraw} setActiveMenuDraw={setActiveMenuDraw} textType={textType} inputref={inputref} obj={obj} h={h}/>

                      {/* TEXT */}
                      <CanvasText activeMenuDraw={activeMenuDraw} setActiveMenuDraw={setActiveMenuDraw} activeMenuText={activeMenuText} setActiveMenuText={setActiveMenuText}  activeMenuBg={activeMenuBg} setActiveMenuBg={setActiveMenuBg} textType={textType} inputref={inputref}/>


                  </div>
                    <CanvasTitle/>

                    <div className={styles.canvas}>
                        <canvas
                            id="c"
                            width={640}
                            height={480}
                        />
                    </div>

                    <div id="empty_container" className={styles.empty_container} style={{display: 'none'}} >
                        <p className={styles.empty_p}>Please, share a picture</p>
                    </div>

                    <div className={styles.buttons_container}>

                        <button type="button"  className={styles.button_back} onClick={() => goBack()}> <p className={styles.p, styles.back}>Back</p></button>

                        <div className={styles.send_container}>
                            <button className={styles.send_button} onClick={ togglePopup }> Submit message <FontAwesomeIcon icon={faPaperPlane} className={styles.send_plane}/></button>
                        </div>

                        {/* POPUP */}
                        <PopUp cancelSend={cancelSend} history={props.history}/>

                        {/* CATCH ERROR UPLOAD */}
                        <CatchError />
                        
                        {/* CLEAR */}
                        <PopUpClear history={props.history}/>
                    </div>
                </div>
            </div>
        </div>)
        : 
        null
        }
        </CanvasContext.Provider>
        </>
    );
}

export default Canvas;