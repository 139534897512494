import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import HttpsRedirect from 'react-https-redirect';

import Login from './components/Login';
import User from './components/User';
import UserVideo from './components/UserVideo';
import Home from './components/Home'
import Video from './components/Video';
import Canvas from './components/Canvas';
import Wall from './components/Wall';
import Display from './components/Display';

import CanvasResponsive from './components/CanvasResponsive'
import { ScaleContext } from './UserContext';

import Cookies from "universal-cookie";

import $ from 'jquery'

import history from "./history.js";

import { gsap, Power3, Power4 } from "gsap";

import helpers from './components/Helpers/Anims'
import "./App.css";

import {
  isMobile,
} from "react-device-detect";

function App() {

  const [scale, setScale] = useState(1);

  // UseEffect to do Barba js transition
  let screen = useRef(null);
  let body = useRef(null);


  const [zoomDragging, setZoomDragging] = useState(false)

  const [showOverlay, setShowOverlay] = useState(false);
  const [render, setRender] = useState(false)

  const [introVideo, setIntroVideo] = useState(false)

  const [playvideos, setPLayVideos] = useState(false)

  const [readyVideo, setReadyVideo] = useState(false)

  const [startDisplay, setStartDisplay] = useState(false)

  const videoUpdate = useRef()

  const [isAuth, setIsAuth] = useState(false)

  const cookies = new Cookies();

  // For display page
  const [renderDisplay, setRenderDisplay] = useState(false)
  const [endRender, setEndRender] = useState(false)

  const [goPage, setGoPage] = useState(false)

  const tlTest = useRef();
  useEffect(() => {
      if(render){

          tlTest.current = gsap.timeline({
              paused: false
          })
      
          tlTest.current.to(screen, {
              duration: 1.2,
              width: "100%",
              left: "0%",
              ease: Power3.easeInOut,
          });

          tlTest.current.call(() => {
              // console.log(`Termino TimeLine, go to: `, goPage)
              // history.push(goPage)
          })
          
          tlTest.current.to(screen, {
            duration: 1,
            left: "100%",
            ease: Power3.easeInOut,
            delay: 0.3,
          });
        
          tlTest.current.set(screen, { left: "-100vw" });
    
          tlTest.current.to(body, .3, {css: {
              opacity: "1",
              pointerEvents: "auto",
              ease: Power4.easeInOut
          }}).delay(0);
    
    
          tlTest.current.to(body, 1, {css: {
              opacity: "1",
          }});
      }
      
  },[render, goPage, history]);





  const tlTestDisplay = useRef();
  useEffect(() => {
    if(renderDisplay){

      tlTestDisplay.current = gsap.timeline({
            paused: false
        })
    
        
        tlTestDisplay.current.to(screen, {
            duration: 1.2,
            // width: "100%",
            opacity: 1,
            // left: "0%",
            display: 'block',
            ease: Power3.easeInOut,
        });
        tlTestDisplay.current.call(() => {
            // console.log(`Termino TimeLine, go to: `, goPage)
            // history.push(goPage)
        })

    }
    
},[renderDisplay]);




  useEffect(() => {
    if( endRender ){

      tlTestDisplay.current.to(screen, {
          duration: 1,
          left: "0%",
          ease: Power3.easeInOut,
          delay: 0.3,
      });
      
      helpers.showThumbs('#container .hover_zoom');

      gsap.to("#logo", { opacity: 1 })

      tlTestDisplay.current.to(screen, { opacity: 0 , ease: Power3.easeInOut,  duration: 1, display: 'none', onComplete: () => {  $("#videoIntro")[0].currentTime = 0 }});

      tlTestDisplay.current.to(body, .3, {css: {
          opacity: "1",
          pointerEvents: "auto",
          ease: Power4.easeInOut,
          
      }, onComplete: () => {
        $('body').removeClass( "initWall" );
        setReadyVideo(true)
        setIntroVideo(false)
        setEndRender(false)
        setRenderDisplay(false)
      }}).delay(0);


      tlTestDisplay.current.to(body, 1, {css: {
          opacity: "1",
      }});


      // $('body').delay(3000).removeClass( "initWall" );
    }
  }, [endRender])
  

  useEffect(() => {


    console.log('entra_playvideos', playvideos, renderDisplay, endRender)

    if( window.location.pathname === '/' || window.location.pathname === '/view' ||   ( window.location.pathname === '/display' && startDisplay ) ){

      

      setReadyVideo(false)

      $("#videoIntro")[0].currentTime = 0

      gsap.fromTo("#intro ",
      {opacity: 0, display: 'none'}, 
      {opacity: 1, display:'block', ease: 'Circ.out', onComplete: () => {
        $("#videoIntro")[0].play();
        // setIntroVideo(false);
        setPLayVideos(false)
      }});

    } 
  }, [playvideos, startDisplay, window.location.pathname ])
  

  useEffect(() => {
    if(introVideo){
      setEndRender(true)
    }
  }, [introVideo])


  useEffect(() => {
      // console.log('BUENO', playvideos, renderDisplay)
  }, [playvideos, renderDisplay])
  

  async function timeUpdateVideo(){
    // console.log('timeUpdateVideo', videoUpdate.current.currentTime, videoUpdate.current.duration - 0.8)
    if( videoUpdate.current.currentTime >= videoUpdate.current.duration - 1.8){
      setIntroVideo(true)
    }
  }


    async function timeUpdateVideoDesktop(){
    // console.log('timeUpdateVideo', videoUpdate.current.currentTime, videoUpdate.current.duration - 0.8)

    if( videoUpdate.current.currentTime >= videoUpdate.current.duration - 2.2){
      setIntroVideo(true)
    }
  }


  useEffect(() => {

    if(cookies.get('auth')){
      setIsAuth(true)
    }
  }, [])


  // useEffect(() => {
  //   const testing = true;
  //   if(testing && window.location.origin == process.env.REACT_APP_TESTING_MODE_URL){
  //     window.location.replace("https://yamagroup.com/");
  //   }
  // }, [window.location])
  
  

    return (
      <div className="App" data-barba="container">


        <div className="load_container">
            <div className="load_screen" ref={(el) => (screen = el)}>
              <div id='intro'>
                { isMobile ? 
                    <video id='videoIntro' ref={videoUpdate} onTimeUpdate={ () => { timeUpdateVideo() }}  playsInline={true} autoPlay={false} muted={true} preload="auto">
                      <source src='/assets/mobile.webm' type="video/webm" />
                      <source src='/assets/mobile.mp4' type="video/mp4; codecs=hvc1"/>
                      Your browser does not support the video tag.
                    </video>
                    : 
                    <video id='videoIntro' ref={videoUpdate} onTimeUpdate={ () => { timeUpdateVideoDesktop() }} playsInline={true} autoPlay={false} muted={true} preload="auto">
                        <source src='/assets/desktop.webm' type="video/webm"/>
                        <source src='/assets/desktop.mp4' type="video/mp4; codecs=hvc1"/>
                        Your browser does not support the video tag.
                    </video>
                }
              </div>
            </div>
        </div>

          <ScaleContext.Provider value={{ isAuth, setIsAuth, scale, setScale, setStartDisplay, zoomDragging, setZoomDragging, showOverlay, setShowOverlay, setRender, render, setRenderDisplay, renderDisplay, setEndRender, endRender, setGoPage, goPage, setPLayVideos, playvideos, readyVideo, setIntroVideo}}>
            <BrowserRouter history={history}>
                <Route path={["/", "/view", "/userVideo"]} exact component={Wall} />
                <Route>
                  <Route path="/create" exact component={Canvas}/>
                  <Route path="/submit" exact component={User} />
                  <Route path="/home" exact component={Home} />
                  { process.env.REACT_APP_WALL_VIDEO === "true" && <Route path="/video" exact component={Video} /> }
                  <Route path="/createresponsive" exact component={CanvasResponsive}/>
                  { startDisplay &&  <Route path="/display" exact component={Display}/>}
                  { window.location.pathname === '/display' && !startDisplay && <button className='startDisplay' onClick={() => setStartDisplay(true)}>START DISPLAY</button> }
                  
                </Route>
            </BrowserRouter>
          </ScaleContext.Provider>
      </div>
    );
}

export default App;
