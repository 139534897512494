import React, { useState, useEffect, useRef} from 'react'
import styles from './css/home.module.css';

import logo from '../assets/logo.png';

import history from "../history";
import { withRouter } from "react-router-dom";
import gsap from 'gsap';

import $ from "jquery"

import option1 from '../assets/option1.svg'
import option2 from '../assets/option2.svg'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isMobile,
    isIPad13
  } from "react-device-detect";

import PopUpRotate from "./Canvas_components/PopUpRotate.jsx";

import helpers from './Helpers/Anims';

import { ScaleContext } from '../UserContext';

const Home = (props) => {

    const context = React.useContext(ScaleContext)

    // var orientation;
    const [landscape, setOrientation] = useState(false)
    const [onRotate, setOnRotate] = useState(false)

    useEffect(() => {

        context.setRender(false)

        if (window.matchMedia("(orientation: portrait)").matches) {
            // alert("You are in portrait mode");
            // setOrientation("portrait")
            // orientation = "portrait"
            setOrientation(false)
        }
            
        if (window.matchMedia("(orientation: landscape)").matches) {
            // alert("You are in landscape mode");
            // setOrientation("landscape")
            // orientation = "landscape"
            setOrientation(true)
        }

        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", function() {
                setOnRotate(!onRotate)
            }, false);
        }
    
    }, [onRotate])


    // Gsap
    const el = useRef();
    const homeLogo = useRef();
    const elHomeBg = useRef();
    const tl = useRef();

    useEffect(() => { 

        tl.current = gsap.timeline({
            paused: false
        })

        if( props.location.state && props.location.state.from == "Wall" ){
            helpers.showLayer("#homeBgLayer", 1)
        }

        if(props.location.state && props.location.state.from == "Wall" ||  props.location.state && props.location.state.from == "Canvas" || props.location.state && props.location.state.from == "Video"){

            helpers.showElement("#HomeLogo", 1)
            helpers.showElements("#home", 1.5)
            gsap.to("#home #card", { opacity: 1 })

            helpers.showElement("#footer", 1.7)

        } else {
            // helpers.showElement("#HomeLogo", 1)
            helpers.showElements("#home", 1)
            gsap.to("#home #card", { opacity: 1 })
        }
        
        
        


    }, [props.history.length]);


    // console.log("props", props)
    const [show, setShow] = useState(true)

    const [showImage, setShowImage] = useState(false)
    const [showVideo, setShowVideo] = useState(false)



    // Callback of gsap anims
    async function goToMessajeCb(){
        props.history.push('/create')
    }

    async function goToMessajeCbResponsive(){
        props.history.push('/createresponsive')
    }


    async function goToMessaje(){

        if(isMobile || isIPad13 ){
            helpers.hideElements("#home", 0, goToMessajeCbResponsive)
        } else { 
            await helpers.hideElements("#home", 0, goToMessajeCb)
        }

    }


    async function goToVideoCb(){
        props.history.push('/video')
    }

    async function goToVideo(){
        // We use pass the params to gsap anim, callback necessary because history.push y async by its onw.
        await helpers.hideElements("#home", 0, goToVideoCb)
    }


    async function goToViewCb(){
        props.history.push('/view')
    }

    async function goToView(){
        helpers.hideElements("#home", 0, goToViewCb)

        await context.setRenderDisplay(false);
        await context.setPLayVideos(true);
    }
    
    return (
        <>
            {landscape && isMobile && !isIPad13 ?
                <PopUpRotate/>
            :
            null }
        <div id="home" style={{position:'absolute', top:'0', zIndex: '10', left: 0}}>
            <div id="homeBgLayer" ref={elHomeBg} className={styles.bg}></div>

            <div id="logoHome" className={[styles.log].join(' ')}>
                <div  className={styles.log_divs}>
                    <img id="HomeLogo" className={styles.logIMG} src="/assets/venture.png" ref={homeLogo}></img>
                </div>
            </div>
            <div className={[styles.card_container, "anim"].join(' ')} >
                    <div className={styles.card} id="card" ref={el}>
                        <p className={styles.card_p2}>Congrats, BELGIUM!<br/><br/></p>
                        <p className={styles.card_p1}>Choose below to post your brief message</p>
                        <div className={styles.button_container}>
                            { process.env.REACT_APP_WALL_VIDEO === "true" &&
                                <div className={styles.options_container}>
                                    <button type='button' className={styles.button} onClick={() => {goToVideo()}}> <img src="/assets/video.svg" /> </button>
                                    <p> Record Video<br/>Message </p>
                                </div>
                            }
                            <div className={styles.options_container}>
                                <button type='button' className={styles.button} onClick={() => {goToMessaje()}}> <img src="/assets/messages.svg" style={{ width: "30px"}}/> </button>
                                <p> Post Written <br/>Message </p>
                            </div>
                        </div>
                        
                        <div className={styles.container_back}>
                            <p onClick={() => goToView()} className={styles.expP}>Explore Posts</p>
                        </div>
                    </div>
                </div>
            {/* <div className={[styles.footer, "anim"].join(' ')}></div> */}
        </div>
        </>
    )
}
export default withRouter(Home)