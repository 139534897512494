import React, { useEffect, useRef, useState} from 'react';
import { CanvasContext } from '../UserContext'

import { fabric } from 'fabric';
import axios from 'axios';
import closeBlack from '../assets/close.svg'

import $ from 'jquery'

import styles from './css/canvasresponsive.module.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIgloo, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

/* Import Components */
import CanvasTitle from './Canvas_components/Title'
import CanvasUploadResponsive from './Canvas_components/UploadResponsive'
import CanvasDrawResponsive from './Canvas_components/DrawResponsive'
import CanvasTextResponsive from './Canvas_components/TextResponsive'
import PopUp from './Canvas_components/PopUp'
import CatchError from './Canvas_components/CatchError'
import PopUpClear from './Canvas_components/PopUpClear'
// import SelectorResponsive from './Canvas_components/SelectorResponsive'


import {
    isMobile,
    isIPad13
} from "react-device-detect";

import PopUpRotate from "./Canvas_components/PopUpRotate.jsx";

import { ScaleContext } from '../UserContext';

import helpers from './Helpers/Anims';

import framewall from '../assets/frame.png'

const MY_URL = process.env.REACT_APP_SECRET_URL

function Canvas(props){

    const context = React.useContext(ScaleContext)

    const [landscape, setOrientation] = useState(false)
    const [onRotate, setOnRotate] = useState(true)

    useEffect(() => {

        context.setRender(false)

        // Función para manejar el cambio de orientación
        const handleOrientationChange = () => {
          if (window.matchMedia("(orientation: portrait)").matches) {
            setOrientation(true);
          } else if (window.matchMedia("(orientation: landscape)").matches) {
            setOrientation(false);
          }
        };
      
        // Agregar el evento orientationchange y guardar la función de limpieza
        if ('onorientationchange' in window) {
          window.addEventListener("orientationchange", handleOrientationChange);
        }
      
        // Función de limpieza para eliminar el evento cuando el componente se desmonta
        return () => {
          if ('onorientationchange' in window) {
            window.removeEventListener("orientationchange", handleOrientationChange);
          }
        };
      
    }, [onRotate]); // No es necesario agregar dependencias aquí si solo se agrega el evento una vez
      



    useEffect(() => {
        helpers.fadeIn("#canvasResponsive", 1)
    }, [])


    const [show, setShow] = useState(true)
    const [canvas2, setCanvas2] = useState(false)
    const [textbox2, setTexbox2] = useState(false)
    const [canvas_bg, setCanvas_bg] = useState("#af1c86")
    var  textbox, canvas, textbox1, textboxTitle, divWhite, frame

    const inputref = useRef();
    const textType = useRef();

    const [inputValue, setInputValue] = useState(5)

    const name = localStorage.getItem('name');

    const [activeMenuBg, setActiveMenuBg] = useState(false)
    const [activeMenuText, setActiveMenuText] = useState(false)
    const [activeMenuDraw, setActiveMenuDraw] = useState(false)

    useEffect(() => {
        if(activeMenuText){
          $("#textSelectorResponsive").css('background', '#f2f2f2')
        } else {
          $("#textSelectorResponsive").css('background', 'transparent')
        }
  
      }, [activeMenuText])
  
  
      useEffect(() => {


          if(activeMenuDraw){
            $("#drawSelectorResponsive").css('background', '#f2f2f2')
          } else {
              $("#drawSelectorResponsive").css('background', 'transparent')
          }
    
      }, [activeMenuDraw])
  
  
      useEffect(() => {
      if(!activeMenuText && !activeMenuDraw){
          $("#selectorPointerResponsive").css('background', '#f2f2f2')
      } else {
          $("#selectorPointerResponsive").css('background', 'transparent')
      }
  
      }, [activeMenuText, activeMenuDraw])
    
    
    useEffect( ()  => {

        // $('body').css('background-size', 'inherit')

        canvas = new fabric.Canvas("c", { preserveObjectStacking:true , backgroundColor: '#032835' } );
        canvas.selection = false;

        setCanvas_bg(canvas)

        setCanvas2(canvas)

        if(name != null ){

            if(!isIPad13){
                textbox = new fabric.Textbox( `${name}`, {
                    fontSize: 13,
                    right: 10,
                    fontFamily: 'Caveat',
                    fontWeight: 'normal',
                    top: 218,
                    width: 310,
                    textAlign: 'right',
                    editable: false,
                    evented: false,
                    selectable: false,
                    fill: '#fff',

                });
                canvas.add(textbox)

                setTexbox2(textbox)


                fabric.Image.fromURL('', function(myImg) {
                    frame = myImg.set({
                        id:'frame',
                        editable: false,
                        evented: false,
                        selectable: false,
                        left: 0,
                        zIndex: 1000,
                    })
                    frame.scaleToWidth(320);
                    canvas.add(frame)
    
                    frame.moveTo(10)
                })
    
                setTexbox2(textbox)


            } else {
                textbox = new fabric.Textbox( name, {
                    fontSize: 25,
                    left: 0,
                    fontFamily: 'Caveat' ,
                    fontWeight: 'normal',
                    top: 300,
                    width: 450,
                    textAlign: 'center',
                    editable: false,
                    evented: false,
                    selectable: false,
                    fill: 'white',
                });
                canvas.add(textbox)
                setTexbox2(textbox)
            }


            // textbox1 = new fabric.Textbox("Text", {
            //     fontSize: 35,
            //     left: 100,
            //     top: 100,
            //     id: 'textbox',
            //     width: 120,
            //     textAlign: 'center',
            //     fill: 'white',
            //     // fontFamily: 'Crafty Girls'
            //     fontFamily: 'Open Sans',
            // });
            // canvas.discardActiveObject()

            // canvas.add(textbox1);
            // textbox1.moveTo(100);
    

        } else {
            props.history.push("/submit")
        }

        textbox = new fabric.Textbox("Type your message here", {
            fontSize: 15,
            left: 35,
            top: 80,
            id: 'textbox',
            width: 250,
            textAlign: 'center',
            fill: '#fff',
            fontFamily: 'Open Sans',
            padding: 20,
            cornerSize: 18,
        });

        canvas.discardActiveObject()

        textbox.selectAll()

        canvas.add(textbox);
        canvas.bringForward(textbox);

        // canvas.setActiveObject(textbox)
        // textbox.enterEditing();
        // setActiveMenuText(true)


        divWhite = new fabric.Textbox("", {
            fontSize: 1000,
            left: 0,
            fontFamily: 'Oooh Baby' ,
            fontWeight: 'bold',
            top: -10,
            height: 1000,
            width: 320,
            textAlign: 'center',
            editable: false,
            evented: false,
            selectable: false,
            // fill: '#ffffff',
            backgroundColor:  'transparent'
            // shadow: shadow
        });
        
        canvas.add(divWhite)
        canvas.sendToBack(divWhite)


        function addDeleteBtn(x, y){
            $(".deleteBtn").remove();
            var btnLeft = x-40;
            var btnTop = y+25;
            var deleteBtn = '<img src="https://yamalabs.com/dev/wall_imgs/042-trash.svg" class="deleteBtn" style="position:absolute;top:'+btnTop+'px;left:'+btnLeft+'px;cursor:pointer;width:20px;height:20px; background: white; padding: 5px; border-radius: 22px;"/>';
            $(".canvas-container").append(deleteBtn);
            $(".canvas-container").css("overflow", "hidden");
        }

        function onCero(e){ 
            if (e.key == "Backspace") {
                canvas.remove(canvas.getActiveObject());
                $(".deleteBtn").remove();
                setActiveMenuText(false)
            }
            return;
        };


        canvas.on('object:selected',function(e){
            addDeleteBtn(e.target.oCoords.tr.x, e.target.oCoords.tr.y);

            setTimeout(() => {

                if(e.target.id == "textbox"){
                    if(e.target.selected && !e.target.isEditing){
                        document.addEventListener('keydown', onCero(e), false);
                        setActiveMenuText(true)
                    }

                    if(e.target.text === "Type your message here") {
                        e.target.text = "";
                        console.log('e.target.text', e.target.text)
                        canvas.renderAll();
                    };

                } else {
                    // onCero()
                }
                
            }, 100);
            

        });

        canvas.on('selection:cleared',function(e){
            console.log('fired')
            $(".deleteBtn").remove();
        })


        canvas.on('mouse:down',function(e){
            if(!canvas.getActiveObject())
            {
                $(".deleteBtn").remove();
                setActiveMenuText(false)
            }else{
                var obj = canvas.getActiveObject();
                var object_type = obj.type;
    
                console.log('object_type', object_type)

                if(object_type == "textbox"){
                    setActiveMenuText(true)
                }
            }
        });


        
        canvas.on('object:modified',function(e){
            addDeleteBtn(e.target.oCoords.tr.x, e.target.oCoords.tr.y);
        });
        
        canvas.on('object:scaling',function(e){
            $(".deleteBtn").remove(); 
        });
        canvas.on('object:moving',function(e){
            $(".deleteBtn").remove(); 
        });
        canvas.on('object:rotating',function(e){
            $(".deleteBtn").remove(); 
        });
        $(document).on('click',".deleteBtn",function(){
            if(canvas.getActiveObject())
            {
                canvas.remove(canvas.getActiveObject());
                $(".deleteBtn").remove();

                setActiveMenuText(false)
            }
        });


        textbox.on('changed', function() {
            var currentCharacters = textbox.text.length;
            let newFontSize;
            console.log('currentCharacters', currentCharacters)

            if(currentCharacters < 50){
                newFontSize = 15;
                textbox.set('fontSize', newFontSize);
            } else if(currentCharacters >= 50 && currentCharacters < 70){
                newFontSize = 14;
                textbox.set('fontSize', newFontSize);
            } else if (currentCharacters >= 100 && currentCharacters < 150){
                newFontSize = 13;
                textbox.set('fontSize', newFontSize);
            } else if (currentCharacters >= 150 ){
                newFontSize = 12;
                textbox.set('fontSize', newFontSize);
            }

        })

        
    }, []);
   

    function uploadCompleted(){
            $("#objectUploading").hide()
            $("#objectDone").css('opacity', '1')

            $("#pUploading").hide()
            $("#pDone").show()

            $("#buttonUploading").hide()
            $("#buttonDone").show()
    }


    async function afterSend(url) {

        const AWS = require('aws-sdk')
        AWS.config.update({
            // accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            // secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
            region: 'us-east-1',
            acl: 'public-read'
        })

        const s3 = new AWS.S3();
        var params = {
            Bucket: process.env.REACT_APP_BUCKET,
        };

        

        s3.listObjectsV2(params, async function (err, data) {
            await data.Contents.map( async (element) => {
                let responseUrl = await element.Key.substring(element.Key.indexOf("_")+1);

                if(responseUrl == url){
                    uploadCompleted()
                }
            });
        })

    }


    async function createImg(){
        canvas2.discardActiveObject().renderAll();

        let canvas3 = document.getElementById('c')
        let config = {
            'content-type' : 'aplicattion/json',
            'Access-Control-Allow-Origin': '*'
        }

        // await addText2() // CHECK IF WE NEED THIS TO RERENDER TEXT SIGN
        await togglePopup()

        canvas3.toBlob( async function(blob) {

            const regex = /[^\w]+/gi
            let nameRegex = name.replace(regex, '')
    
            function getCurrentDate() {
                const date = new Date();
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const day = date.getDate().toString().padStart(2, '0');
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const seconds = date.getSeconds().toString().padStart(2, '0');
                return `${year}_${month}_${day}_${hours}${minutes}${seconds}`;
            }
              
            const fileDate = getCurrentDate();

            const formData = new FormData();
            const myFileImg = await new File(
                [blob],
                `${fileDate}_${nameRegex}.png`,
            { type: 'image/png' });

            formData.append('myMessage', myFileImg);

            localStorage.setItem('messageUrl', myFileImg.name);

            await axios.post(`${MY_URL}view`, formData, config)
            .then( res => {
                console.log('res', res)
                setTimeout(() => {
                    uploadCompleted()
                }, 3000);
            })
            .catch(err => {
                console.log('err', err)
                cancelSend()
                document.getElementById('uploadError').style.display = 'block'
            });
        }, "image/jpeg", 0.8  );
    }


    var isRedoing = false;
    var h = [];
    var obj = []
    const timeR = useRef(null)
    const cancel = useRef(false)

    function cancelSend(){
        document.getElementById('popUp').style.display = 'none'
        cancel.current = true
        window.clearTimeout(timeR.current)
    }

    async function togglePopup() {
        obj = canvas2.getObjects()

        let imagesObj ;

        await obj.map( x => {
            if(x.id == "fabric_js"){
                console.log('x', x)
                imagesObj = 1
            } 
        })


        // if( imagesObj != 1){
        //     alert('Please, share a picture')
            // document.getElementById('empty_container').style.display = 'block'
            // setTimeout(() => {
            //     document.getElementById('empty_container').style.display = 'none'
            // }, 3000);

        // } else {
            // cancel.current = false
            document.getElementById('popUp').style.display = 'block'
            timeR.current = setTimeout( async () =>{
                await createImg()
                window.clearTimeout(timeR.current)
            }, 5000)
        // }
    }

    function gifInfo(){
        document.getElementById('info').style.display = 'block'
    }

    //TEXT
    function addText() {    
        //Genero la funcion de agregar textos
        document.getElementById('empty_container').style.display = 'none'
        obj = canvas2.getObjects()
        
        let texts = [];

        obj.map((el, id) => {
            if(el.text !== undefined){
                texts.push(el)
            }
        })

        if( texts.length == 1){
            document.getElementById('empty_container').style.display = 'none'
            canvas2.isDrawingMode = false;
            inputref.current.style.display = 'none';
            textType.current.style.display = 'inline-block';

            textbox = new fabric.Textbox("", {
                fontSize: 50,
                left: 200,
                top: 300,
                id: 'textbox',
                width: 250,
                textAlign: 'center',
                fill: 'white',
                fontFamily: 'Open Sans',
            });
            canvas2.discardActiveObject()
            canvas2.setActiveObject(textbox)
            textbox.enterEditing();

            canvas2.add(textbox);
            

        } else {
            document.getElementById('empty_container').style.display = 'none'
            canvas2.isDrawingMode = false;
            inputref.current.style.display = 'none';
            textType.current.style.display = 'inline-block';
            let textbox1 = texts[1]
            canvas2.setActiveObject(textbox1)
            textbox1.enterEditing();
        }
    }

    async function setText( param, value){

        if(canvas2.getActiveObject()){
            var obj = canvas2.getActiveObject();

            if(obj.id != 'fabric_js'){
                obj.set( param, value);
                canvas2.renderAll();
                canvas2.discardActiveObject()
                canvas2.setActiveObject(obj)
                obj.enterEditing()
                canvas2.renderAll();
            }
        }
    }


    function goBack(){
        document.getElementById('clear').style.display = 'block'
    }


    useEffect(() => {
        if(canvas2.isDrawingMode == false ){
            canvas2.forEachObject(function(obj) {
                obj.editable = true;
            });
        }

    }, [canvas2.isDrawingMode])

    return (
        <> 
        <CanvasContext.Provider value={{canvas2, obj, inputref, textType, textbox2, h, isRedoing, name }}>
            <p  style={{fontFamily: 'Crafty Girls', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Cedarville Cursive', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Rock Salt', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Homemade Apple', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Shadows Into Light', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Caveat', display: 'inline'}}></p>
            <p  style={{fontFamily: 'Oooh Baby', display: 'inline'}}></p>

        { show ? 
        (<div style={{position:'absolute', top:'0', zIndex: '10', left: 0}}>
            { landscape && isMobile && !isIPad13 ?
                <PopUpRotate/>
            :
            null }

            <div className={styles.bg}></div>
            <div id="canvasResponsive" className={styles.card_canvas}>

                
                <div className={styles.container}>

                    <div className={styles.container_close}>
                        <div className={styles.close} >
                            <img className={styles.close_img} src={closeBlack} onClick={() => goBack()}/>
                        </div>
                    </div>

                    <CanvasTitle/>

                    <div className={styles.canvas}>

                        { isIPad13 ? 
                            <canvas
                                id="c"
                                width={480}
                                height={360}
                            />
                        :
                            <canvas
                                id="c"
                                width={320}
                                height={240}
                            />
                        }

                        <div className={styles.hand_buttons}>

                            {/* UPLOAD IMG */}
                            {/* <SelectorResponsive activeMenuDraw={activeMenuDraw} setActiveMenuDraw={setActiveMenuDraw} activeMenuText={activeMenuText} setActiveMenuText={setActiveMenuText}  activeMenuBg={activeMenuBg} setActiveMenuBg={setActiveMenuBg} textType={textType} inputref={inputref}/> */}

                            {/* UPLOAD IMG */}
                            <CanvasUploadResponsive  setActiveMenuDraw={setActiveMenuDraw} setActiveMenuText={setActiveMenuText} textType={textType} inputref={inputref}/>

                            {/* DRAW */}
                            <CanvasDrawResponsive activeMenuBg={activeMenuBg} setActiveMenuBg={setActiveMenuBg} activeMenuText={activeMenuText} setActiveMenuText={setActiveMenuText} inputValue={inputValue} setInputValue={setInputValue} activeMenuDraw={activeMenuDraw} setActiveMenuDraw={setActiveMenuDraw} textType={textType} inputref={inputref} obj={obj} h={h}/>

                            {/* TEXT */}
                            <CanvasTextResponsive activeMenuDraw={activeMenuDraw} setActiveMenuDraw={setActiveMenuDraw} activeMenuText={activeMenuText} setActiveMenuText={setActiveMenuText}  activeMenuBg={activeMenuBg} setActiveMenuBg={setActiveMenuBg} textType={textType} inputref={inputref}/>

                        </div>

                    </div>

                    <div id="empty_container" className={styles.empty_container} style={{display: 'none'}} >
                        <p className={styles.empty_p}>Please, share a picture</p>
                    </div>

                    <div className={styles.buttons_container}>

                        <div className={styles.send_container}>
                            <button className={styles.send_button} onClick={ togglePopup }> Submit message <FontAwesomeIcon icon={faPaperPlane} className={styles.send_plane}/></button>
                        </div>
                    </div>
                </div>
            </div>

            {/* POPUP */}
            <PopUp cancelSend={cancelSend} history={props.history}/>

            {/* CATCH ERROR UPLOAD */}
            <CatchError />

            {/* CLEAR */}
            <PopUpClear history={props.history}/>
        </div>) 
        : 
        null
        }
        </CanvasContext.Provider>
        </>
    );
}

export default Canvas;