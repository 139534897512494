import React, {useState, useEffect, useRef} from 'react'

import styles from './css/video.module.css';
import { fabric } from 'fabric';

import logo from '../assets/logo.png';
import closeBlack from '../assets/close.svg'
import angle_left from "../assets/undoDrawing.svg"


import VideoRecorder from 'react-video-recorder'
import 'react-html5-camera-photo/build/css/index.css';
import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIgloo, faPaperPlane } from '@fortawesome/free-solid-svg-icons';

import PopUp from './Canvas_components/PopUp'


import $ from 'jquery'
import gsap from 'gsap/gsap-core';

import DeviceOrientation, { Orientation } from 'react-screen-orientation'

import {
    BrowserView,
    MobileView,
    isBrowser,
    isSafari,
    isMobile,
    isIPad13,
    browserName
  } from "react-device-detect";


import { ScaleContext } from '../UserContext';

import helpers from './Helpers/Anims';


import PopUpRotate from "./Canvas_components/PopUpRotate.jsx";

const Video = (props) => {

    const context = React.useContext(ScaleContext)

    // console.log(`${browserName}`);

    // let url;
    var orientation;
    var randomShared = getCurrentDate()


    function getCurrentDate() {
        const date = new Date();
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${year}_${month}_${day}_${hours}${minutes}${seconds}`;
    }
      
    // const fileDate = getCurrentDate();


    if (window.matchMedia("(orientation: portrait)").matches) {
        // alert("You are in portrait mode");
        // setOrientation("portrait")
        orientation = "portrait"
    }
      
    if (window.matchMedia("(orientation: landscape)").matches) {
        // alert("You are in landscape mode");
        // setOrientation("landscape")
        orientation = "landscape"
    }



    // var orientation;
    const [landscape, setOrientation] = useState(false)
    const [onRotate, setOnRotate] = useState(false)
    

    useEffect(() => {

        if (window.matchMedia("(orientation: portrait)").matches) {
            // alert("You are in portrait mode");
            // setOrientation("portrait")
            // orientation = "portrait"
            setOrientation(false)
        }
            
        if (window.matchMedia("(orientation: landscape)").matches) {
            // alert("You are in landscape mode");
            // setOrientation("landscape")
            // orientation = "landscape"
            setOrientation(true)
        }

        if ('onorientationchange' in window) {
            window.addEventListener("orientationchange", function() {
                setOnRotate(!onRotate)
            }, false);
        }
    
    }, [onRotate])


    const nameVideo = localStorage.getItem('name');
    console.log('name', nameVideo)

    let  textbox, canvas, textbox1

    const [canvas2, setCanvas2] = useState(false)
    const [textbox2, setTexbox2] = useState(false)
    const [isRecording, setiIsRecording] = useState(false)
    const [isPreview, setIsPreview] = useState(false)
    const [isCameraOn, setIsCameraOn] = useState(false)
    const [isVideoView, setIsVideoView] = useState(false)
    const [url, setUrl] = useState()
    const [randomChart, setRandomChart] = useState()
    const [requestPermissions, setRequestPermissions] = useState(false)
    const [permissions, setPermissions] = useState(false)
    const [stream, setStream] = useState(null);

    const videoRecorderRef = useRef()


    const timeRVideo = useRef(null)
    const cancelVideo = useRef(false)


    const tlVideo = useRef();
    const elVideoForm = useRef();
    const elVideoBg = useRef();
    // This useEffect is for GSAP animations
    useEffect(() => {

        tlVideo.current = gsap.timeline({
            paused: false
        })

        helpers.fadeIn("#video", 1)

    }, [])


    // useEffect to create img as sign for video
    useEffect(() => {

        canvas = new fabric.Canvas("canvasVideo", { preserveObjectStacking:true , } );
        canvas.selection = false;

        setCanvas2(canvas)


        if(window.innerWidth < 560){
            if(nameVideo != null){
                textbox = new fabric.Textbox( nameVideo, {
                    fontSize: 15,
                    left: 0,
                    fontFamily: 'Caveat',
                    top: 170,
                    width: 240,
                    textAlign: 'right',
                    editable: false,
                    evented: false,
                    selectable: false,
                    fill: 'white',
                    });
                
                canvas.add(textbox)
                setTexbox2(textbox)
            }
        } else {
            if(nameVideo != null){
                textbox = new fabric.Textbox( nameVideo, {
                    fontSize: 15,
                    left: 0,
                    fontFamily: 'Caveat',
                    top: 170,
                    width: 240,
                    textAlign: 'right',
                    editable: false,
                    evented: false,
                    selectable: false,
                    fill: 'white',
                    });
                
                canvas.add(textbox)
                setTexbox2(textbox)
            }

        }
        

    }, [nameVideo])


    // UseEffect for state isRecording
    useEffect(() => {
        // console.log(`isRecording in Use Effect`, videoRecorderRef.current.state.isRecording)

        if(isRecording == true){
            videoRecorderRef.current.handleStartRecording()
            videoRecorderRef.current.state.isRecording = true
            // console.log(`Inside useEffect is recording true`)
        }
    }, [isRecording])

    function startRecordingBtn(){

        setTimeout(() => {
            $('.video-recorder__Video-sc-7k20rv-2').get(0).play()
        }, 100);

        // console.log(`Video`, $('.video-recorder__Video-sc-7k20rv-2').get(0))        
        setiIsRecording(true)
    }


    function stopRecordingBtn(){
        // setiIsRecording(false)
        videoRecorderRef.current.handleStopRecording()
    }

    // UseEffect for state isPreview
    useEffect(() => {

        // console.log(`Preview en UseEffect`, videoRecorderRef.current)
        if(isPreview == false){
            videoRecorderRef.current.handleStopReplaying()
            let videoRecorded = document.getElementsByClassName("video-recorder__Video-sc-7k20rv-2")
            videoRecorded.autoplay = true
            videoRecorded.muted = false
            // console.log(`Inside useEffect preview is false`, videoRecorderRef.current)
        }
    }, [isPreview])

    function cancelPreview(){
        setIsPreview(false)
    }


    function uploadCompleted(){
        $("#objectUploading").hide()
        $("#objectDone").css('opacity', '1')

        $("#pUploading").hide()
        $("#pDone").show()

        $("#buttonUploading").hide()
        $("#buttonDone").show()
    }


    async function afterSend(url) {

        const AWS = require('aws-sdk')
        // AWS.config.update({
        //     accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        //     secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        //     region: 'us-east-1',
        //     acl: 'public-read'
        // })

        const s3 = new AWS.S3();
        var params = {
            Bucket: process.env.REACT_APP_BUCKET,
        };

        const intervalID = setInterval(async function(){
            s3.listObjectsV2(params, async function (err, data) {
                await data.Contents.map( async (element) => {
                    
    
                    if (browserName == "Safari"){
    
                        let responseUrl = await element.Key.substring(element.Key.indexOf("_")+1);
                        let urlExt = await url.replace(/\.[^/.]+$/, ".mp4")

                        // console.log('responseUrl',responseUrl)
                        // console.log('urlExt',urlExt)
    
                        if(responseUrl == urlExt){
                            // alert('TENGO LA EXT MP4')
                            // console.log(`tengo mismo archivo`)
                            clearInterval(intervalID)
                            uploadCompleted()
                            // props.history.push("/view")
                        }
    
                    } else {
    
                        let responseUrl = await element.Key.substring(element.Key.indexOf("_")+1);
    
                        if(responseUrl == url){
                            clearInterval(intervalID)
                            uploadCompleted()
                        }
                    }
    
    
                    
                });
            })
        }, 3000)
        

    }

    async function videoImage(){

        // console.log(`randomShared in videoImage`, randomChart)

        canvas2.discardActiveObject().renderAll();

        let canvas3 = document.getElementById('canvasVideo')
        const config2 = {
            'content-type' : 'aplicattion/json',
            'Access-Control-Allow-Origin': '*'
        }


        function getCurrentDate() {
            const date = new Date();
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            return `${year}_${month}_${day}_${hours}${minutes}${seconds}`;
        }
          
        const fileDate = getCurrentDate();

        canvas3.toBlob( async function(blobIMg) {
            const formDataIMG = new FormData();

            const myFileImg = await new File(
                [blobIMg],
                "_"+  randomChart + "_videoSign" + ".png",
            { type: 'image/png' });

            // console.log(`myFileImg`, myFileImg)
            formDataIMG.append('myVideoSign', myFileImg);

            await axios.post(process.env.REACT_APP_POST_ENDPOINT_VIDEO, formDataIMG, config2)
            .then( res => {
                console.log('res', res)
                setTimeout(() => {
                    uploadCompleted()
                }, 8000);
            })
            .catch(err => {
                console.log('err', err)
                cancelSend()
                document.getElementById('uploadError').style.display = 'block'
            });

        });

    }


    async function SubmitVideo(){

        // console.log(`randomShared in Submit Video`, randomChart)
        console.log('SubmitVideo', url)

        await videoImage()

        if( url ){
            const config = {
                'content-type' : 'aplicattion/json',
                'Access-Control-Allow-Origin': '*'
              }
          
              const formData = new FormData();

                // console.log( "CAMBIO de nombre...." ,   )
          
              formData.append('myMessage', url);
          
              await axios.post(`${process.env.REACT_APP_SECRET_URL}view`, formData, config)
            //   .then( res => {
            //       // document.getElementById('popUp').style.display = 'none'
            //       // window.location.href = "/view";

            //       console.log('res', res)
            //   })
            //   .catch(err => {
            //     console.log(err)
            //     //   cancelSend()
            //     //   document.getElementById('uploadError').style.display = 'block'
            //   });
        }
    }


    function togglePopup(randomShared) {
        $('.video-recorder__Video-sc-7k20rv-2').get(0).pause()
        let videoRecorded = document.getElementsByClassName("video-recorder__Video-sc-7k20rv-2")
        console.log('videoRecorded', videoRecorded)

        videoRecorded.muted = true
        
        // videoRecorderRef.current.state.isReplayingVideo = false

        // console.log(`STATE`, videoRecorderRef.current.state)

        // videoRecorderRef.state.isReplayingVideo
        cancelVideo.current = false
        document.getElementById('popUp').style.display = 'block'
        timeRVideo.current = setTimeout( async () =>{
            await SubmitVideo(randomShared)
            window.clearTimeout(timeRVideo.current)
        }, 5000)
    }

    function cancelSend(){
        $('.video-recorder__Video-sc-7k20rv-2').get(0).play()
        document.getElementById('popUp').style.display = 'none'
        cancelVideo.current = true
        window.clearTimeout(timeRVideo.current)
    }


    function goHomeCB() {
        props.history.push("/home" ,  { from: 'Video' })
    }

    function goHome() {
        helpers.fadeOut("#video", 0, goHomeCB)
    }

    const checkCameraPermission = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setStream(mediaStream);
            setRequestPermissions(false)
        } catch (error) {
          setRequestPermissions(true)
        }
    };

    const handleStopCapture = () => {
        if (stream) {
            stream.getTracks().forEach(track => track.stop());
            setStream(null);
        }
    };

    useEffect(() => {
        checkCameraPermission();
    }, []);

    return (
        <div style={{position:'absolute', top:'0', zIndex: '10', left: 0}}>
            {landscape && isMobile && !isIPad13 ?
                <PopUpRotate/>
            :
            null }
            {/* <div className={styles.log}>
                <div className={styles.log_divs}>
                    <img className={styles.logIMG} src={logo}></img>
                </div>
            </div> */}

                <div ref={elVideoBg} className={styles.bg}></div>

                <div id="video" ref={elVideoForm} className={styles.card_container}>

                    <div className={styles.container_close} >
                        <div className={styles.close}>
                            <img  src={closeBlack} onClick={() => goHome()}/>
                        </div>
                    </div>

                    <div className={styles.card} >
                        <p className={styles.card_p2}>Upload your video!</p>
                        { isPreview  &&
                            <p className={styles.card_p1}>Tap on video to preview sound</p>
                        }
                        <div className={styles.button_container}>

                        {/* Video */}

                        { !requestPermissions ? 
                            ( orientation === "portrait" ? 
                                <VideoRecorder
                                    id="videoRecorder"
                                    style={{ width: "750px !important"}}
                                    // chunkSize={250}0
                                    // mimeType="video/webm;codecs=vp8,opus"
                                    constraints={{
                                    //     width: { exact: 480, ideal: 480 },
                                    //     height: { exact: 640, ideal: 640 },
                                    //     aspectRatio: { ideal: 1 },
                                        resizeMode: "crop-and-scale",
                                        audio: true,
                                    //     video: {
                                    //         width: 480,
                                    //         height: 640
                                    //     }
                                    }}
                                    onCameraOn={ () => setIsCameraOn(true)}
                                    ref={videoRecorderRef}
                                    onRecordingComplete
                                    countdownTime={0}
                                    timeLimit={10000}
                                    dataAvailableTimeout={500}
                                    isFlipped={false}
                                    isOnInitially
                                    onRecordingComplete={async (videoBlob) => {
                                    // Do something with the video...
                                        // console.log('videoBlob', videoBlob)

                                        const myFileVideo = new File(
                                            [videoBlob],
                                            `Vertical${randomShared}.webm`,
                                            { type: 'video/webm' });

                                        
                                        localStorage.setItem('messageUrl', myFileVideo.name);
                                        // console.log("my URL... ", url)
                                        setUrl(myFileVideo)
                                        setRandomChart(`Vertical${randomShared}`)

                                        await setIsPreview(true)
                                        await setiIsRecording(false)

                                        // 3. Set the video's `src` to the object URL
                                        // var video = document.getElementById("videoBlob");
                                        // video.src = url;
                                    }}
                                />
                            :
                            
                                <VideoRecorder
                                    id="videoRecorder"
                                    style={{ width: "750px !important"}}
                                    // chunkSize={250}
                                    // mimeType="video/webm;codecs=vp8,opus"
                                    constraints={{
                                    //     width: { exact: 480, ideal: 480 },
                                    //     height: { exact: 640, ideal: 640 },
                                    //     aspectRatio: { ideal: 1 },
                                        resizeMode: "crop-and-scale",
                                        audio: true,
                                    //     video: {
                                    //         width: 640,
                                    //         height: 480
                                    //     }
                                    }}
                                    onCameraOn={ () => setIsCameraOn(true)}
                                    ref={videoRecorderRef}
                                    onRecordingComplete
                                    countdownTime={0}
                                    timeLimit={10000}
                                    dataAvailableTimeout={500}
                                    isFlipped={false}
                                    isOnInitially
                                    onRecordingComplete={async (videoBlob2) => {
                                    // Do something with the video...
                                    // console.log('videoBlob', videoBlob2)

                                        const myFileVideo = new File(
                                            [videoBlob2],
                                            ""+ randomShared + ".webm",
                                        { type: 'video/webm' });

                                        localStorage.setItem('messageUrl', myFileVideo.name);
                                        // console.log("my URL... ", url)
                                        
                                        setUrl(myFileVideo)
                                        setRandomChart(randomShared)
                                        
                                        
                                        await setIsPreview(true)
                                        await setiIsRecording(false)


                                        // 3. Set the video's `src` to the object URL
                                        // var video = document.getElementById("videoBlob");
                                        // video.src = url;
                                    }}
                                />      
                            )
                            :
                            <div className='alllow_permissions'>
                                <p>Please allow camera and microphone<br/>to record message</p>
                                { isSafari && <button className='btn btn-request' onClick={() => { checkCameraPermission() }}>Request permissions</button> }
                            </div>
                        }

                            <div className={styles.videoSignHidden}>
                                { orientation === "portrait" ? 
                                    <canvas
                                        id="canvasVideo"
                                        width={250}
                                        height={191}
                                    />
                                    : 
                                    <canvas
                                        id="canvasVideo"
                                        width={250}
                                        height={191}
                                    />
                                }
                            </div>

                            {/* <button type='button' className={styles.button} onClick={() => {goToVideo()}}> Upload Video</button> */}
                            {/* <button type='button' className={styles.button} onClick={() => {nextPage()}}> Upload Image</button> */}
                            {/* <p onClick={() => setShow(false)} className={styles.expP}>Explore Wall</p> */}
                        </div>

                        { !isRecording && !isPreview && isCameraOn?
                        
                            <div className={styles.recordDiv}>
                                <button className={styles.button} onClick={() => startRecordingBtn()}>RECORD</button>
                            </div>
                        : 
                            null
                        }

                        { isRecording && 
                            <div className={styles.recordDiv}>
                                <button className={styles.buttonStop} onClick={() => stopRecordingBtn()}></button>
                            </div>
                        }

                        { isPreview  ?  
                            <div className={styles.containerPreview}>
                                <button className={styles.buttonSubmit}  onClick={ () => togglePopup(randomShared)}>Post your Video <FontAwesomeIcon icon={faPaperPlane} className={styles.send_plane}/> </button>
                                <div className={styles.cancelPreview}>
                                    <button type="button"  className={styles.button_back} onClick={() => cancelPreview()}><p className={styles.p, styles.back}>Record again <img className={styles.angle_left} src={angle_left} />  </p></button>
                                </div>
                            </div>
                        : null }
                        
                    </div>

                    {/* POPUP */}
                    <PopUp cancelSend={cancelSend} history={props.history}/>

                </div>
        </div>
    )
}

export default Video
