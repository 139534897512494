import React, { useEffect, useRef } from 'react';

import styles from '../css/canvas.module.css';

import arrow from '../../assets/upload_done.svg'
import anim from '../../assets/upload_anim.svg'

import gsap from 'gsap/gsap-core';

import helpers from '../Helpers/Anims';

import { ScaleContext } from '../../UserContext';

function PopUp(props){

    const context = React.useContext(ScaleContext)
    
    const tlPopUp = useRef();
    const elPopUpForm = useRef();
    // This useEffect is for GSAP animations
    useEffect(() => {
        tlPopUp.current = gsap.timeline({
            paused: false
        })

        tlPopUp.current.fromTo([elPopUpForm.current], { opacity: 0 }, {opacity: 1, duration: 0.5 }, 1 );

    }, [])


    function goWall(){
        // localStorage.setItem('sentMessage', 'true');

        setTimeout(() => {
            // props.history.push('/view')
            window.location.href=`${process.env.REACT_APP_SECRET_URL}view`;
        }, 1100);
        
        context.setRender(true)
    }

return (
    <div id='popUp' ref={elPopUpForm} className={styles.popupV} style={{display: 'none'}}>
        <div className={styles.popup_innerT}>
            <div className={styles.div_warning_popup} style={{ display: 'flex', justifyContent:'center', textAlign: 'center', width: '60px', height: '60px'}}>
                <div style={{ position : 'relative'}}>

                <object type="image/svg+xml" id="objectUploading" data={anim}  style={{ width: '80px', position: 'absolute', left: '50%', top: '0%', transform: 'translate(-50%, -12%)'}}>
                    <img src={anim} />
                </object>

                <object type="image/svg+xml" id="objectDone" data={arrow}  style={{ width: '80px', opacity: '0', position: 'absolute', left: '50%', top: '0%', transform: 'translate(-50%, -12%)'}}>
                    <img src={arrow} />
                </object>

                </div>
            </div> 
            <div className={styles.container_popupT}>
                <div className={styles.div_img_popupT}>
                    <p id="pUploading" className={styles.p_popupT2}>We are uploading your message </p>
                    <p id="pDone" className={styles.p_popupT3}>Your message is posted </p>
                </div>
                <div className={styles.div_img_popupD2}>
                    <button id="buttonUploading" onClick={props.cancelSend} className={styles.button_popupV2}>Cancel</button>
                    <button id="buttonDone" className={styles.button_popupV3} onClick={ () => goWall()}>VIEW POSTS</button>
                </div>
            </div>
        </div>
    </div>
    )
}

export default PopUp;